import {
  Container,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import {
  Button,
  SWAPLogo,
  SWAPSpace,
  TextField,
  Typography,
} from '@yosgo/swap-ui'
import { memo, ReactChild, useCallback, useEffect, useState } from 'react'
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded'
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Legend } from 'recharts'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'

import AuthModal from '../Common/AuthModal'
import LazyLoadImg from '../Common/LazyLoadImg'
import Slider from 'react-slick'
import { PricingHead } from '../Common/PagesHead'
import { getPaymentRequestFeeRatesFromGuess } from '../../utils/apprequest/price/feeRate'
import LoadToShow from '../Common/LoadToShow'
import useWindowSize from '../Hooks/useWindowSize'
import { PathOr } from '../../utils/FunctionsBox'
import { ParseAccountToken } from '../../utils/HandleToken'
import PathAndTitle from '../../utils/PathAndTitle'

export const Product_Name_SearchEngine = '曝光媒合服務'
export const Product_Name_Invoice = '案件請款服務'
export const Product_Name_Verified = '事業認證服務'
export const Product_Name_Consult = '事業諮詢服務'

const _Line: any = Line
const backgroundColor = '#334267'

export const PricingTable = memo(() => {
  const [windowWidth, _] = useWindowSize()
  const theme = useTheme()
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))
  const xsDown = useMediaQuery(theme.breakpoints.down('xs'))
  const [sohoAuthModal, setSohoAuthModal] = useState(false)
  const [isLogin, setIsLogin] = useState(false)

  const handleScroll = (elementId: string) => {
    const el = document.querySelector(`#${elementId}`)
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }

  const tableWidth = 1154
  const tableHeight = 1174
  const [tableScale, setTableScale] = useState(1)

  useEffect(() => {
    if (windowWidth > 0) {
      const width = windowWidth - (xsDown ? 16 : 24) * 2
      const _tableScale = width / tableWidth
      setTableScale(_tableScale > 1 ? 1 : _tableScale)
    }
  }, [windowWidth])

  const handleCheckSOHOisLogin = useCallback(() => {
    const userType = PathOr(undefined, ['type'], ParseAccountToken())
    setIsLogin(userType ? true : false)
  }, [])
  useEffect(() => {
    handleCheckSOHOisLogin()
  }, [handleCheckSOHOisLogin])

  return (
    <>
      <AuthModal
        open={sohoAuthModal}
        onClose={() => setSohoAuthModal(false)}
        type={'register'}
      />
      {windowWidth > 0 ? (
        <div
          style={{
            background: backgroundColor,
          }}
        >
          <Container maxWidth="xl">
            <SWAPSpace size={mdDown ? 40 : 80} />
            <Typography variant={mdDown ? 'h2' : 'h1'} color="white">
              適合各類型自由工作者
              {mdDown ? <br /> : '，'}
              用多少付多少
            </Typography>
            {mdDown ? null : <SWAPSpace size={40} />}
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                margin:
                  tableScale < 1
                    ? `-${((1 - tableScale) * tableHeight) / 2}px 0px`
                    : '0',
              }}
            >
              <div
                style={{
                  transformOrigin: '50% 50%',
                  transform: `scale(${tableScale})`,
                  width: tableWidth,
                }}
              >
                <div
                  style={{
                    padding: 0,
                    width: tableWidth,
                    overflow: 'hidden',
                    background: 'white',
                    borderRadius: '12px',
                  }}
                >
                  <Table
                    aria-label="customized table"
                    // size={mdDown ? 'small' : 'medium'}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ minWidth: '300px' }}></TableCell>
                        <TableCell
                          align="left"
                          width={350}
                          style={{
                            minWidth: '320px',
                            borderRight: '1px solid rgba(55,55,55,0.1)',
                            borderLeft: '1px solid rgba(55,55,55,0.1)',
                          }}
                        >
                          <SWAPSpace />
                          <Typography variant="h2">標準方案</Typography>
                          <SWAPSpace size={9} />
                          <Typography variant="body2">
                            免費註冊會員，無痛開始。適合新手、剛開始接案的自由工作者！
                          </Typography>
                          <SWAPSpace />
                        </TableCell>
                        <TableCell
                          align="left"
                          width={350}
                          style={{
                            minWidth: '320px',
                          }}
                        >
                          <SWAPSpace />
                          <Typography variant="h2">贊助方案</Typography>
                          <SWAPSpace size={9} />
                          <Typography variant="body2">
                            每年 4,000
                            元，更多的功能、固定的手續費率。適合案源穩定的自由工作者！
                          </Typography>
                          <SWAPSpace />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {[
                        {
                          feature: Product_Name_Verified,
                          standard: '',
                          sponsor: '',
                          standardDescription: '',
                          sponsorDescription: '',
                          subItem: false,
                          url: '/functions/verify',
                        },
                        {
                          feature: '實名認證勳章',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '免費使用',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '事業認證勳章',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '免費使用',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '贊助會員專屬勳章',
                          standard: (
                            <HighlightOffRoundedIcon color="secondary" />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '不支援',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: Product_Name_SearchEngine,
                          standard: '',
                          sponsor: '',
                          subItem: false,
                          url: '/functions/profile',
                        },
                        {
                          feature: '個人接案履歷頁面',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '免費使用',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '登錄 Google 索引',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '免費使用',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '刊登至 SWAP 平台',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '免費使用',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: Product_Name_Invoice,
                          standard: ``,
                          sponsor: '',
                          subItem: false,
                          url: '/functions/invoice',
                        },
                        {
                          feature: '平台報價單',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: '免費使用',
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '平台請款單',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: (
                            <>
                              2.5% ~ 4.5% 手續費，
                              <Button
                                size="small"
                                variant="text"
                                style={{
                                  background: 'rgba(0,0,0,0)',
                                  padding: '6px 0px',
                                  display: 'inline-block',
                                  verticalAlign: 'middle',
                                  marginTop: -4,
                                }}
                                onClick={() => {
                                  handleScroll('feeRate')
                                }}
                              >
                                了解更多
                              </Button>
                            </>
                          ),
                          sponsorDescription: (
                            <>
                              1% 手續費，
                              <Button
                                size="small"
                                variant="text"
                                style={{
                                  background: 'rgba(0,0,0,0)',
                                  padding: '6px 0px',
                                  display: 'inline-block',
                                  verticalAlign: 'middle',
                                  marginTop: -4,
                                }}
                                onClick={() => {
                                  handleScroll('feeRate')
                                }}
                              >
                                了解更多
                              </Button>
                            </>
                          ),
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '進階功能：先開發票再付款',
                          standard: (
                            <HighlightOffRoundedIcon color="secondary" />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: `不支援`,
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '進階功能：大額請款 ',
                          standard: (
                            <HighlightOffRoundedIcon color="secondary" />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: `不支援`,
                          sponsorDescription: '免費使用',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '進階功能：跨國請款',
                          standard: (
                            <HighlightOffRoundedIcon color="secondary" />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: `不支援`,
                          sponsorDescription: '專案報價',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: Product_Name_Consult,
                          standard: '',
                          sponsor: '',
                          subItem: false,
                          url: '/#contact',
                        },
                        {
                          feature: '實體諮詢',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: `首次免費`,
                          sponsorDescription: '免費使用，不限次數',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '線上諮詢(Zoom, Google Meet)',
                          standard: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          sponsor: (
                            <CheckCircleOutlineRoundedIcon
                              style={{ color: 'green' }}
                            />
                          ),
                          standardDescription: `首次免費`,
                          sponsorDescription: '免費使用，不限次數',
                          subItem: true,
                          url: '',
                        },
                        {
                          feature: '',
                          standard: (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                gap: '4px',
                              }}
                            >
                              <Button
                                fullWidth
                                size="large"
                                variant="secondary"
                                onClick={() => {
                                  if (isLogin)
                                    window.location.href =
                                      PathAndTitle.member.home.path
                                  else setSohoAuthModal(true)
                                }}
                                style={{ display: 'block' }}
                              >
                                立即註冊
                              </Button>
                              <Typography
                                variant="small2"
                                style={{ textAlign: 'center' }}
                              >
                                免費註冊會員
                              </Typography>
                            </div>
                          ),
                          sponsor: (
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                gap: '4px',
                              }}
                            >
                              <Button
                                fullWidth
                                size="large"
                                variant="primary"
                                onClick={() => {
                                  if (isLogin)
                                    window.location.href =
                                      PathAndTitle.member.plan.path
                                  else setSohoAuthModal(true)
                                }}
                              >
                                前往贊助頁面
                              </Button>
                              <Typography
                                variant="small2"
                                style={{ textAlign: 'center' }}
                              >
                                贊助前請先註冊會員
                              </Typography>
                            </div>
                          ),
                          subItem: false,
                          url: '',
                        },
                      ].map((item, index) => (
                        <TableRow key={`service_${index}`}>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{
                              background: item.subItem
                                ? 'white'
                                : 'rgba(0, 53, 172, 0.05)',
                            }}
                          >
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <Typography
                                variant={item.subItem ? 'body1' : 'h6'}
                              >
                                {item.subItem ? (
                                  <>
                                    <span
                                      style={{
                                        width: '30px',
                                        display: 'inline-block',
                                      }}
                                    />
                                    ⌙{' '}
                                  </>
                                ) : null}
                                {item.feature}
                              </Typography>
                              {item.url ? (
                                <Link href={item.url} target="_blank">
                                  <OpenInNewIcon
                                    color="primary"
                                    style={{
                                      fontSize: '24px',
                                      marginTop: '4.5px',
                                      marginLeft: '4px',
                                    }}
                                  />
                                </Link>
                              ) : null}
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              background: item.subItem
                                ? 'white'
                                : 'rgba(0, 53, 172, 0.05)',
                              borderRight: '1px solid rgba(55,55,55,0.1)',
                              borderLeft: '1px solid rgba(55,55,55,0.1)',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 12,
                              }}
                            >
                              {item.standard}
                              <Typography variant="body1">
                                {item.standardDescription}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              background: item.subItem
                                ? 'white'
                                : 'rgba(0, 53, 172, 0.05)',
                            }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: 12,
                              }}
                            >
                              {item.sponsor}
                              <Typography variant="body1">
                                {item.sponsorDescription}
                              </Typography>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </div>
            <div id="feeRate" />
            <SWAPSpace size={mdDown ? 40 : 120} />
          </Container>
        </div>
      ) : null}
    </>
  )
})

const PricingRate = memo(
  ({
    feeRateData,
    fee,
  }: {
    feeRateData: Array<{
      month: number
      year: number
      rate: number
      name: string
      sponsor: number
    }>
    fee: number
  }) => {
    const [data, setData] = useState<
      Array<{
        month: number
        year: number
        rate: number
        name: string
        sponsor: number
      }>
    >([])

    useEffect(() => {
      if (feeRateData && feeRateData.length > 0) {
        let _value = feeRateData
        _value.push({
          month: 0,
          year: 0,
          rate: feeRateData[feeRateData.length - 1]?.rate,
          name: '',
          sponsor: 1,
        })
        setData(_value)
      }
    }, [feeRateData])

    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))
    const smDown = useMediaQuery(theme.breakpoints.down('sm'))
    const xsDown = useMediaQuery(theme.breakpoints.down('xs'))

    const chartW = xsDown ? 320 : smDown ? 375 : 480

    return (
      <div>
        <SWAPSpace size={mdDown ? 40 : 120} />
        <Container maxWidth="xl">
          <Grid
            container
            spacing={6}
            alignItems="center"
            direction={mdDown ? 'column-reverse' : 'row'}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              id="chart_wrap"
              style={{ overflow: 'hidden' }}
            >
              {mdDown ? <SWAPSpace size={40} /> : null}
              {data && data.length > 0 ? (
                <>
                  <Typography
                    variant="body2"
                    style={{ padding: '0 0 15px 20px' }}
                  >
                    近 3 個月費率
                  </Typography>
                  <LineChart
                    width={chartW}
                    height={mdDown ? 450 : 510}
                    data={data}
                    margin={{ top: 0, right: 0, bottom: 0, left: -40 }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="name"
                      interval={0}
                      tickSize={0}
                      tick={({ x, y, payload }) => (
                        <g
                          transform={`translate(${
                            x + chartW / data.length / 1.3
                          },${y})`}
                        >
                          <text
                            x={7}
                            y={0}
                            dy={16}
                            textAnchor="end"
                            fill="rgba(0,0,0,1)"
                            fontSize={'0.875rem'}
                          >
                            {payload.value}
                          </text>
                        </g>
                      )}
                    />
                    <YAxis
                      type="number"
                      domain={[0, 4.5]}
                      tickCount={10}
                      interval={1}
                      tickSize={0}
                      tick={({ x, y, payload }) => (
                        <g transform={`translate(${x - 5},${y - 12})`}>
                          <text
                            x={0}
                            y={0}
                            dy={16}
                            textAnchor="end"
                            fill="#666"
                            fontSize={10}
                          >
                            {payload.value}%
                          </text>
                        </g>
                      )}
                    />
                    <Legend
                      wrapperStyle={{
                        width: 'auto',
                        left: 'calc(50% - 160px)',
                        bottom: '-15px',
                        textAlign: 'center',
                      }}
                      formatter={(value) => {
                        if (value === 'rate') {
                          return <div>標準方案的請款單費率</div>
                        } else {
                          return (
                            <div style={{ paddingLeft: 18 }}>
                              贊助方案的請款單費率
                            </div>
                          )
                        }
                      }}
                    />
                    <_Line
                      isAnimationActive={false}
                      type="stepAfter"
                      dataKey="rate"
                      stroke="rgba(246, 161, 40,1)"
                      strokeWidth={3}
                      dot={false}
                      label={({ x, y, stroke, value }: any) => {
                        return (
                          <g
                            className="normal_percentage_label"
                            transform={`translate(${
                              chartW / data.length / 1.5
                            },${22})`}
                          >
                            <text
                              x={x}
                              y={y}
                              dy={-4}
                              fill={stroke}
                              fontSize={15}
                              textAnchor="middle"
                            >
                              {value}%
                            </text>
                          </g>
                        )
                      }}
                    />
                    <_Line
                      isAnimationActive={false}
                      type="monotone"
                      dataKey="sponsor"
                      stroke="rgba(0, 147, 42,1)"
                      strokeWidth={3}
                      dot={false}
                      label={({ x, y, stroke, value }: any) => {
                        return (
                          <g
                            className="vip_percentage_label"
                            transform={`translate(${
                              chartW / data.length / 1.5
                            },${22})`}
                          >
                            <text
                              x={x}
                              y={y}
                              dy={-4}
                              fill={stroke}
                              fontSize={15}
                              textAnchor="middle"
                            >
                              {value}%
                            </text>
                          </g>
                        )
                      }}
                    />
                  </LineChart>
                </>
              ) : null}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
              <Typography variant="h1">請款單收費方式</Typography>
              <SWAPSpace />
              <Typography
                variant="h5"
                style={{ fontWeight: 'normal' }}
                color="black900"
              >
                請款單手續費將於客戶完成付款後自動從案件款項中扣除。每月 SWAP
                平台將依據平台交易量公告新的請款單手續費費率，費率範圍為 2.5% ~
                4.5%。
              </Typography>
              <SWAPSpace size={40} />

              <Typography
                variant="h5"
                color="black900"
                style={{ fontWeight: 'normal' }}
              >
                計算公式為，請款單手續費 = 付款金額 x 當月費率
              </Typography>
              <SWAPSpace size={40} />
              <Typography
                variant="h5"
                color="black900"
                style={{ fontWeight: 'normal' }}
              >
                贊助方案會員請款單費率固定 1% ，不受每月公告費率影響。
              </Typography>
              <SWAPSpace size={40} />
              <Typography
                variant="h5"
                color="black900"
                style={{ fontWeight: 'normal' }}
              >
                舉例本月客戶付款 10,000 元，若為
              </Typography>
              <SWAPSpace />
              <Typography
                variant="h5"
                color="black900"
                style={{ fontWeight: 'normal' }}
              >
                ・標準方案會員，費率為{' '}
                <span
                  style={{
                    color: theme.secondary.secondary800,
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  {fee}%
                </span>
                ，請款單手續費為 {Math.ceil((10000 * fee) / 100)} 元
              </Typography>
              <SWAPSpace />
              <Typography
                variant="h5"
                color="black900"
                style={{ fontWeight: 'normal' }}
              >
                ・贊助方案會員，費率為
                <span
                  style={{
                    color: theme.success.success800,
                    fontWeight: 'bold',
                  }}
                >
                  {' '}
                  1%{' '}
                </span>
                ，請款單手續費為 {Math.ceil((10000 * 1) / 100)} 元
              </Typography>
            </Grid>
          </Grid>
          <SWAPSpace size={40} />
        </Container>
        <SWAPSpace size={mdDown ? 40 : 120} />
      </div>
    )
  }
)

interface FlowAndPricingProps {
  fee: number
  title?: string
  description?: string
}
export const FlowAndPricing = memo(
  ({ fee, title, description }: FlowAndPricingProps) => {
    const [clientInput, setClientInput] = useState(0)
    const [clientInsuranceAmount, setClientInsuranceAmount] = useState(0)
    const [platformAmount, setPlatformAmount] = useState(0)
    const [_, setSohoNetIncome] = useState(0)
    const [sohoIncomeTaxAmount, setSohoIncomeTaxAmount] = useState(0)
    const [sohoInput, setSohoInput] = useState(0)
    const [sohoInsuranceAmount, setSohoInsuranceAmount] = useState(0)
    const [taxAmount, setTaxAmount] = useState(0)

    const [memberPlan, setMemberPlan] = useState<'NORMAL' | 'SPONSOR'>('NORMAL')
    const [laborCode, setLaborCode] = useState<'9A' | '9B' | '50'>('9A')
    const [withdrawWay, setWithdrawWay] = useState<'ALL' | 'PARTIAL'>('PARTIAL')
    const [isInUnion, setIsInUnion] = useState<'TRUE' | 'FALSE'>('TRUE')

    const [clientInputMsg, setClientInputMsg] = useState('')
    const [sohoInputMsg, setSohoInputMsg] = useState('')

    const theme = useTheme()
    const mdDown = useMediaQuery(theme.breakpoints.down('md'))

    const _fee = memberPlan === 'SPONSOR' ? 1 : fee

    const insurance_fee_rate = 0.0211
    const fifty_insurance_threshold = 25250
    const nine_AB_insurance_threshold = 20000

    const fifty_incomeTax_fee_rate = 0.05
    const fifty_incomeTax_threshold = 86001
    const nine_AB_incomeTax_fee_rate = 0.1
    const nine_AB_incomeTax_threshold = 20010

    const inputMax = 1000000
    const inputMin = 100
    const inputMsg = '↳ 金額範圍一百至一百萬'

    const [width] = useWindowSize()

    const handleCleanAllAmount = () => {
      setClientInsuranceAmount(0)
      setPlatformAmount(0)
      setSohoIncomeTaxAmount(0)
      setSohoInsuranceAmount(0)
      setTaxAmount(0)
    }

    useEffect(() => {
      handleClientInputOnChange(clientInput, false)
      setClientInputMsg('')
      setSohoInputMsg('')
    }, [memberPlan, laborCode, withdrawWay, isInUnion])

    const handleClientInputOnChange = (value: any, onlyCalc?: boolean) => {
      const input = Number(value)
      handleCleanAllAmount()
      if (input < inputMin) {
        setSohoInput(0)
        setClientInput(input)
        setClientInputMsg(inputMsg)
      } else if (input > inputMax) {
        setSohoInput(0)
        setClientInput(0)
        setClientInputMsg(inputMsg)
      } else {
        setClientInputMsg('')
        const _taxAmount = Math.round((input / 1.05) * 0.05)
        const _clientInsuranceAmount =
          laborCode === '50'
            ? Math.round(
                ((input - _taxAmount) / (1 + insurance_fee_rate)) *
                  insurance_fee_rate
              )
            : 0
        const _sohoNetIncome = input - _taxAmount - _clientInsuranceAmount
        const _platformAmount = Math.ceil((input * _fee) / 100)
        const _sohoIncomeTaxAmount =
          withdrawWay === 'PARTIAL'
            ? 0
            : laborCode === '50'
            ? _sohoNetIncome >= fifty_incomeTax_threshold
              ? Math.floor(_sohoNetIncome * fifty_incomeTax_fee_rate)
              : 0
            : _sohoNetIncome >= nine_AB_incomeTax_threshold
            ? Math.floor(_sohoNetIncome * nine_AB_incomeTax_fee_rate)
            : 0
        const _sohoInsuranceAmount =
          isInUnion === 'TRUE'
            ? 0
            : withdrawWay === 'PARTIAL'
            ? 0
            : laborCode === '50'
            ? _sohoNetIncome >= fifty_insurance_threshold
              ? Math.round(_sohoNetIncome * insurance_fee_rate)
              : 0
            : _sohoNetIncome >= nine_AB_insurance_threshold
            ? Math.round(_sohoNetIncome * insurance_fee_rate)
            : 0
        const _sohoInput =
          input -
          _taxAmount -
          _clientInsuranceAmount -
          _platformAmount -
          _sohoIncomeTaxAmount -
          _sohoInsuranceAmount
        if (onlyCalc) {
          return {
            _taxAmount,
            _clientInsuranceAmount,
            _sohoNetIncome,
            _platformAmount,
            _sohoIncomeTaxAmount,
            _sohoInsuranceAmount,
            _sohoInput,
          }
        } else {
          setClientInput(input)
          setTaxAmount(_taxAmount)
          setClientInsuranceAmount(_clientInsuranceAmount)
          setSohoNetIncome(_sohoNetIncome)
          setPlatformAmount(_platformAmount)
          setSohoIncomeTaxAmount(_sohoIncomeTaxAmount)
          setSohoInsuranceAmount(_sohoInsuranceAmount)
          setSohoInput(_sohoInput)
        }
      }
    }

    const handleSOHOtInputOnChange = (value: any) => {
      const input = Number(value)
      handleCleanAllAmount()
      if (input < inputMin) {
        setClientInput(0)
        setSohoInput(input)
        setSohoInputMsg(inputMsg)
      } else if (input > inputMax) {
        setClientInput(0)
        setSohoInput(0)
        setSohoInputMsg(inputMsg)
      } else {
        setSohoInputMsg('')
        let _clientValue = input
        while (
          handleClientInputOnChange(_clientValue, true)?._sohoInput &&
          Number(handleClientInputOnChange(_clientValue, true)?._sohoInput) <
            input
        ) {
          _clientValue++
        }
        if (
          handleClientInputOnChange(_clientValue, true)?._sohoInput &&
          Number(handleClientInputOnChange(_clientValue, true)?._sohoInput) ===
            input
        ) {
          handleClientInputOnChange(_clientValue)
        }
      }
    }

    return (
      <div id="model">
        <style>
          {`
           .grid-template-with-span-child > span:nth-child(1) { grid-area: element1; }
          .grid-template-with-span-child > span:nth-child(2) { grid-area: element2; }
          .grid-template-with-span-child > span:nth-child(3) { grid-area: element3; }
          .grid-template-with-span-child > span:nth-child(4) { grid-area: element4; }
          .grid-template-with-span-child > span:nth-child(5) { grid-area: element5; }
          .grid-template-with-span-child > span:nth-child(6) { grid-area: element6; }
          .grid-template-with-span-child > span:nth-child(7) { grid-area: element7; }
          .grid-template-with-span-child > span:nth-child(8) { grid-area: element8; }
          .grid-template-with-span-child > span:nth-child(9) { grid-area: element9; }
          .grid-template-with-span-child > span:nth-child(10) { grid-area: element10; }
          .grid-template-with-span-child > span:nth-child(11) { grid-area: element11; }
          .grid-template-with-span-child > span:nth-child(12) { grid-area: element12; }

          .flow-input-box label {
            background: rgba(0,0,0,0) !important;
            top: -12px;
            left: -12px;
          }
           `}
        </style>
        <div style={{ background: backgroundColor }}>
          <SWAPSpace size={80} />
          <Container maxWidth="xl">
            <div>
              <Typography
                variant="h1"
                style={{ textAlign: 'center' }}
                color="white"
              >
                {title ? title : '平台交易模式'}
              </Typography>
            </div>
            <SWAPSpace size={12} />
            <Typography
              variant="h6"
              color="white"
              style={{ textAlign: 'center', fontWeight: 'normal' }}
            >
              {description
                ? description
                : '客戶付款給 SWAP，會員再向 SWAP 平台提領報酬。SWAP 依循政府規定扣除營業稅、代扣繳所得稅、二代健保補充保費...等規費。'}
            </Typography>
            <SWAPSpace size={40} />
            <div
              style={{
                border: '1px solid rgb(204, 204, 204)',
                borderRadius: '12px',
                padding: mdDown ? 24 : 48,
                background: 'white',
              }}
            >
              <Typography variant="title">試算表</Typography>
              <SWAPSpace />
              <div
                className="grid-template-with-span-child"
                style={{
                  display: 'grid',
                  gridTemplateColumns:
                    width < 1024 ? '1fr' : '1fr 0fr 1fr 1fr 0fr 1fr',
                  alignItems: 'center',
                  gridRowGap: '12px',
                  gridColumnGap: '12px',
                  gridAutoFlow: 'row dense',
                  gridTemplateAreas:
                    width < 1024
                      ? `
                      "element5"
                      "element6"
                      "element7"
                      "element8"
                      "element1"
                      "element3"
                      "element2"
                      "element4"
                      "element9"
                      `
                      : `
                      ". element1 element1 element2 element2 ."
                      ". element3 element3 element4 element4 ."
                      "element5 element6 element7 element7 element8 element9"
                    `,
                }}
              >
                {/* 國稅局 */}
                <span>
                  <div
                    style={{
                      padding: 18,
                      height: 'auto',
                      borderRadius: '12px',
                      border: '1px solid rgb(204, 204, 204)',
                      background: theme.secondary.secondary50,
                    }}
                  >
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      國稅局
                    </Typography>
                    <SWAPSpace />
                    {[
                      {
                        title: '平台發票營業稅',
                        amount: `${taxAmount.toLocaleString()} 元`,
                      },
                      {
                        title: '會員代扣繳所得稅',
                        amount: `${sohoIncomeTaxAmount.toLocaleString()} 元`,
                      },
                    ].map((item) => {
                      return (
                        <Typography
                          variant="body2"
                          key={item.title}
                          style={{ textAlign: 'center' }}
                        >
                          {item.title} {item.amount}
                        </Typography>
                      )
                    })}
                  </div>
                </span>
                {/* 健保局 */}
                <span>
                  <div
                    style={{
                      padding: 18,
                      height: 'auto',
                      borderRadius: '12px',
                      border: '1px solid rgb(204, 204, 204)',
                      background: theme.secondary.secondary50,
                    }}
                  >
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                      健保局
                    </Typography>
                    <SWAPSpace />
                    {[
                      {
                        title: '平台二代健保補充保費',
                        amount: `${clientInsuranceAmount.toLocaleString()} 元`,
                      },
                      {
                        title: '會員二代健保補充保費',
                        amount: `${sohoInsuranceAmount.toLocaleString()} 元`,
                      },
                    ].map((item) => {
                      return (
                        <Typography
                          variant="body2"
                          key={item.title}
                          style={{ textAlign: 'center' }}
                        >
                          {item.title} {item.amount}
                        </Typography>
                      )
                    })}
                  </div>
                </span>
                {/* 箭頭 */}
                <span>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    {width < 1024 ? '↓' : '↑'}
                  </Typography>
                </span>
                {/* 箭頭 */}
                <span>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    {width < 1024 ? '↓' : '↑'}
                  </Typography>
                </span>
                {/* 客戶 */}
                <span className="flow-input-box">
                  <div
                    style={{
                      background: theme.primary.primary50,
                      padding: 18,
                      height: '180px',
                      borderRadius: '12px',
                      border: '1px solid rgb(204, 204, 204)',
                    }}
                  >
                    <div>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        客戶(公司)
                      </Typography>
                      <SWAPSpace size={36} />
                      <TextField
                        label="付款總金額"
                        height={56}
                        type="text"
                        value={clientInput.toLocaleString()}
                        fullWidth
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        onClick={() => {
                          setSohoInputMsg('')
                        }}
                        onChange={(e: any) => {
                          if (
                            e.nativeEvent.inputType !== 'insertCompositionText'
                          ) {
                            const value = e.target.value.replace(/,/g, '')
                            if (isNaN(Number(value))) return
                            handleClientInputOnChange(
                              Number(value) < 0 ? 0 : Number(value)
                            )
                          }
                        }}
                        InputProps={{
                          endAdornment: '元',
                        }}
                        helperText={
                          clientInputMsg ? (
                            <Typography variant="small2" color="danger500">
                              {clientInputMsg}
                            </Typography>
                          ) : (
                            <Typography variant="small2" color="primary900">
                              ↪ 請試著輸入金額到此欄位
                            </Typography>
                          )
                        }
                      />
                    </div>
                  </div>
                </span>
                {/* 箭頭 */}
                <span>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    {width < 1024 ? '↓' : '→'}
                  </Typography>
                </span>
                {/* 平台 */}
                <span>
                  <div
                    style={{
                      padding: 18,
                      height: '180px',
                      borderRadius: '12px',
                      border: '1px solid rgb(204, 204, 204)',
                      display: 'flex',
                      alignItems: 'center',
                      background: theme.black.black100,
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <div
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <SWAPLogo size={54} />
                      </div>
                      <SWAPSpace />
                      {[
                        {
                          title: 'SWAP 平台請款單手續費',
                          amount: `${platformAmount.toLocaleString()} 元，費率 ${_fee}%`,
                        },
                      ].map((item) => {
                        return (
                          <Typography
                            variant="body2"
                            key={item.title}
                            style={{ textAlign: 'center' }}
                          >
                            {item.title} {item.amount}
                          </Typography>
                        )
                      })}
                    </div>
                  </div>
                </span>
                {/* 箭頭 */}
                <span>
                  <Typography variant="h4" style={{ textAlign: 'center' }}>
                    {width < 1024 ? '↓' : '→'}
                  </Typography>
                </span>
                {/* 會員 */}
                <span className="flow-input-box">
                  <div
                    style={{
                      padding: 18,
                      height: '180px',
                      borderRadius: '12px',
                      border: '1px solid rgb(204, 204, 204)',
                      background: theme.primary.primary50,
                    }}
                  >
                    <div>
                      <Typography variant="h6" style={{ textAlign: 'center' }}>
                        會員(個人)
                      </Typography>
                      <SWAPSpace size={36} />
                      <TextField
                        label="實領總金額"
                        height={56}
                        type="text"
                        fullWidth
                        value={sohoInput.toLocaleString()}
                        inputProps={{
                          inputMode: 'numeric',
                          pattern: '[0-9]*',
                        }}
                        onClick={() => {
                          setClientInputMsg('')
                        }}
                        onChange={(e: any) => {
                          if (
                            e.nativeEvent.inputType !== 'insertCompositionText'
                          ) {
                            const value = e.target.value.replace(/,/g, '')
                            if (isNaN(Number(value))) return
                            handleSOHOtInputOnChange(
                              Number(value) < 0 ? 0 : Number(value)
                            )
                          }
                        }}
                        InputProps={{
                          endAdornment: '元',
                        }}
                        helperText={
                          sohoInputMsg ? (
                            <Typography variant="small2" color="danger500">
                              {sohoInputMsg}
                            </Typography>
                          ) : (
                            <Typography variant="small2" color="primary900">
                              ↪ 請試著輸入金額到此欄位
                            </Typography>
                          )
                        }
                      />
                    </div>
                  </div>
                </span>
              </div>
              <SWAPSpace size={60} />
              <Typography variant="title">結果說明</Typography>
              <SWAPSpace />
              <Typography>
                [說明.1] 客戶付款 {clientInput.toLocaleString()}{' '}
                元，扣除發票營業稅 {taxAmount.toLocaleString()} 元
                {laborCode === '50' &&
                  `、平台二代健保補充保費 ${clientInsuranceAmount.toLocaleString()} 元`}
                與請款單手續費 {platformAmount.toLocaleString()} 元後，會員的
                SWAP 帳戶將入帳{' '}
                {(
                  clientInput -
                  taxAmount -
                  platformAmount -
                  clientInsuranceAmount
                ).toLocaleString()}{' '}
                元。
              </Typography>
              <SWAPSpace />
              <Typography>
                [說明.2] 會員向 SWAP 平台
                {withdrawWay === 'ALL'
                  ? `全額領取報酬，扣除會員代扣繳所得稅 ${sohoIncomeTaxAmount.toLocaleString()} 元與會員二代健保補充保費 ${sohoInsuranceAmount.toLocaleString()} 元，實領總金額 ${sohoInput.toLocaleString()} 元。`
                  : `多期領取報酬，每次提領不超過兩萬元，無須代扣繳會員代扣繳所得稅與會員二代健保補充保費，實領總金額 ${sohoInput.toLocaleString()} 元。`}
              </Typography>
            </div>
            <SWAPSpace />
            <div
              style={{
                border: '1px solid rgb(204, 204, 204)',
                borderRadius: '12px',
                padding: mdDown ? 16 : 48,
                background: 'white',
              }}
            >
              <div>
                <Typography variant="title">試算選項</Typography>
                <SWAPSpace />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: mdDown ? 12 : 24,
                    flexDirection: mdDown ? 'column' : 'row',
                  }}
                >
                  <Typography variant="body1">會員方案</Typography>
                  <RadioGroup
                    row
                    aria-label="memberPlan"
                    name="memberPlan"
                    value={memberPlan}
                    onChange={(e: any) => {
                      setMemberPlan(e.target.value)
                    }}
                  >
                    {[
                      {
                        title: '標準方案',
                        description: `浮動的請款單手續費率，本月費率為 ${fee}%`,
                        code: 'NORMAL',
                      },
                      {
                        title: '贊助方案',
                        description: '請款單手續費固定 1%',
                        code: 'SPONSOR',
                      },
                    ].map((item, index) => (
                      <div key={`plan_${index}`}>
                        <FormControlLabel
                          style={{ width: '300px', alignItems: 'flex-start' }}
                          value={item.code}
                          control={
                            <Radio
                              color="primary"
                              style={{ padding: '0px 9px' }}
                            />
                          }
                          label={
                            <>
                              {item.title}
                              <Typography variant="body2" color="black900">
                                {item.description}
                              </Typography>
                              <SWAPSpace />
                            </>
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </div>
                <SWAPSpace size={36} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: mdDown ? 12 : 24,
                    flexDirection: mdDown ? 'column' : 'row',
                  }}
                >
                  <Typography variant="body1">申報類別</Typography>
                  <RadioGroup
                    row
                    aria-label="laborCode"
                    name="laborCode"
                    value={laborCode}
                    onChange={(e: any) => {
                      setLaborCode(e.target.value)
                    }}
                  >
                    {laborCodes.map((item, index) => (
                      <div key={`labor_${index}`}>
                        <FormControlLabel
                          style={{ width: '300px', alignItems: 'flex-start' }}
                          value={item.code}
                          control={
                            <Radio
                              color="primary"
                              style={{ padding: '0 9px' }}
                            />
                          }
                          label={
                            <>
                              {item.title}
                              <Typography variant="body2" color="black900">
                                {item.description}
                              </Typography>
                              <SWAPSpace />
                            </>
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </div>
                <SWAPSpace size={36} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: mdDown ? 12 : 24,
                    flexDirection: mdDown ? 'column' : 'row',
                  }}
                >
                  <Typography variant="body1">領取方式</Typography>
                  <RadioGroup
                    row
                    aria-label="withdrawWay"
                    name="withdrawWay"
                    value={withdrawWay}
                    onChange={(e: any) => {
                      setWithdrawWay(e.target.value)
                    }}
                  >
                    {withdrawWays.map((item, index) => (
                      <div key={`withdraw_${index}`}>
                        <FormControlLabel
                          style={{ width: '300px', alignItems: 'flex-start' }}
                          value={item.code}
                          control={
                            <Radio
                              color="primary"
                              style={{ padding: '0 9px' }}
                            />
                          }
                          label={
                            <>
                              {item.title}
                              <Typography variant="body2" color="black900">
                                {item.description}
                              </Typography>
                              <SWAPSpace />
                            </>
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </div>
                <SWAPSpace size={36} />
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    gap: mdDown ? 12 : 24,
                    flexDirection: mdDown ? 'column' : 'row',
                  }}
                >
                  <Typography variant="body1">投保工會</Typography>
                  <RadioGroup
                    row
                    aria-label="isInUnionOrNot"
                    name="isInUnionOrNot"
                    value={isInUnion}
                    onChange={(e: any) => {
                      setIsInUnion(e.target.value)
                    }}
                  >
                    {isInUnionOrNot.map((item, index) => (
                      <div key={`isInUnionOrNot${index}`}>
                        <FormControlLabel
                          style={{ width: '300px', alignItems: 'flex-start' }}
                          value={item.code}
                          control={
                            <Radio
                              color="primary"
                              style={{ padding: '0 9px' }}
                            />
                          }
                          label={
                            <>
                              {item.title}
                              <Typography variant="body2" color="black900">
                                {item.description}
                              </Typography>
                              <SWAPSpace />
                            </>
                          }
                        />
                      </div>
                    ))}
                  </RadioGroup>
                </div>
                <SWAPSpace />
              </div>
              <SWAPSpace size={40} />
              <Typography variant="title">備註</Typography>
              <SWAPSpace />
              <div>
                {[
                  {
                    content: (
                      <>
                        SWAP 平台依據
                        <Link
                          href="https://law.moj.gov.tw/LawClass/LawAll.aspx?pcode=G0340082"
                          target="_blank"
                        >
                          《統一發票使用辦法》
                        </Link>
                        於客戶付款給 SWAP 平台後開立發票並繳交營業稅。
                      </>
                    ),
                  },
                  {
                    content: (
                      <>
                        SWAP 平台依據
                        <Link
                          href="https://law.moj.gov.tw/LawClass/LawAll.aspx?pcode=g0340003"
                          target="_blank"
                        >
                          《所得稅法》
                        </Link>
                        與
                        <Link
                          href="https://law.moj.gov.tw/LawClass/LawAll.aspx?PCode=L0060027"
                          target="_blank"
                        >
                          《全民健康保險扣取及繳納補充保險費辦法》
                        </Link>
                        ，於匯款案件報酬給會員時開立勞務報酬單並依據規定協助會員代扣繳所得稅與二代健保補充保費。
                      </>
                    ),
                  },
                  {
                    content: (
                      <>
                        代扣繳所得稅與二代健保補充保費的扣繳門檻與說明{' '}
                        <Link
                          href="https://blog.swap.work/tax-nhi/tax-nhi/"
                          target="_blank"
                        >
                          請點此查看。
                        </Link>
                      </>
                    ),
                  },
                  {
                    content: (
                      <>
                        SWAP
                        平台採用國泰世華銀行，若客戶/會員並非國泰世華銀行之帳戶，匯款時會有
                        30
                        元的銀行跨行匯款手續費，實際費用視不同銀行可能會有差異。
                      </>
                    ),
                  },
                ].map((item, index) => (
                  <div key={`備註_${index}`}>
                    <Typography variant="body1" color="black800">
                      [註.{index + 1}] {item.content}
                    </Typography>
                    <SWAPSpace size={18} />
                  </div>
                ))}
              </div>
            </div>
          </Container>
          <SWAPSpace size={80} />
        </div>
      </div>
    )
  }
)

const laborCodes: Array<{
  title: string
  description: ReactChild
  code: '9A' | '9B' | '50'
}> = [
  {
    title: '9A 執行業務所得',
    description: <>自負盈虧的專業工作者，論件計酬，如工程師、設計師。</>,
    code: '9A',
  },
  {
    title: '9B 稿費',
    description: <>漫畫/劇本/詞曲/文字類著作，並有實體作品可供檢核。</>,
    code: '9B',
  },
  {
    title: '50 薪資',
    description: (
      <>
        工作具有固定工作時間、地點，固定報酬的特性，例如工讀。本項所得會有平台二代健保補充保費。
      </>
    ),
    code: '50',
  },
]
const withdrawWays: Array<{
  title: string
  description: string
  code: 'ALL' | 'PARTIAL'
}> = [
  {
    title: '多期領取報酬',
    description: '每日提領一次，每次提領金額不超過新台幣兩萬元。',
    code: 'PARTIAL',
  },
  {
    title: '全額領取報酬',
    description:
      '一次提領全額。提領金額超過扣繳門檻時須代扣繳個人所得稅與會員二代健保補充保費。',
    code: 'ALL',
  },
]
const isInUnionOrNot: Array<{
  title: string
  description: string
  code: 'TRUE' | 'FALSE'
}> = [
  {
    title: '已投保工會',
    description: '若有投保工會，於投保期間內可免除會員二代健保補充保費。',
    code: 'TRUE',
  },
  {
    title: '尚未投保工會',
    description: '',
    code: 'FALSE',
  },
]

export const PricingCta = memo(() => {
  const [open, setOpen] = useState(false)

  const theme = useTheme()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'))
  const mdDown = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <div>
      <style>
        {`
          .slick-slide {
            padding: 0 12px;
          }
          .testimonial_avatar img {
            object-fit: cover;
            object-position: top;
          }
        `}
      </style>
      <div style={{ background: '#f9f9f9' }}>
        <SWAPSpace size={mdDown ? 40 : 80} />
        <div
          style={{ maxWidth: '1200px', padding: '0 16px', margin: '0 auto' }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div>
              <Typography variant={mdDown ? 'h1' : 'd3'} color="primary700">
                新世代自由工作者的一致選擇
              </Typography>
              <SWAPSpace size={12} />
              <Typography variant="h3">來自 SWAP 會員的好評！</Typography>
            </div>
            <div>
              <SWAPSpace size={24} />
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                size="large"
                endIcon={<ArrowForwardIcon />}
              >
                免費註冊
              </Button>
              <SWAPSpace size={24} />
            </div>
          </div>
          {open && (
            <AuthModal
              type="register"
              open={open}
              onClose={() => setOpen(false)}
            />
          )}
        </div>
        {mobile ? null : <SWAPSpace size={40} />}
        {/* 用戶見證 */}
        <div style={{ overflow: 'hidden', paddingBottom: mdDown ? 40 : 80 }}>
          <div>
            {/* 手機版 */}
            {mobile ? (
              <div style={{ maxWidth: '1200px', padding: '16px' }}>
                <div>
                  {testimonialData.map((item, index) => {
                    return (
                      <div key={`${item.name}_${index}`}>
                        <div
                          style={{
                            padding: '24px',
                            width: '100%',
                            boxSizing: 'border-box',
                            boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.1)',
                            border: 'none',
                            borderRadius: '12px',
                          }}
                        >
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'stretch',
                            }}
                          >
                            <img
                              src="https://swap-img.swap.work/landingPageImg2/testimonial/sign.png"
                              style={{
                                marginRight: '12px',
                                marginTop: '3px',
                                objectFit: 'contain',
                                objectPosition: 'top',
                              }}
                            />
                            <Typography variant="body2_loose" color="black800">
                              {item.comment}
                            </Typography>
                            <img
                              src="https://swap-img.swap.work/landingPageImg2/testimonial/sign_reverse.png"
                              style={{
                                marginLeft: '12px',
                                width: '14px',
                                marginBottom: '4px',
                                objectFit: 'contain',
                                objectPosition: 'bottom',
                              }}
                            />
                          </div>
                          <SWAPSpace size={24} />
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '16px',
                            }}
                          >
                            <div className="testimonial_avatar">
                              <LazyLoadImg
                                src={item.imgUrl}
                                layout="intrinsic"
                                width="56px"
                                height="56px"
                                style={{
                                  width: '56px',
                                  height: '56px',
                                  borderRadius: '12px',
                                  overflow: 'hidden',
                                }}
                                alt="user image"
                                priority={false}
                                placeholder="empty"
                                objectFit="cover"
                              />
                            </div>

                            <div>
                              <Typography variant="h6" color="primary500">
                                {item.name}
                              </Typography>
                              <Typography variant="body2">
                                {item.profession}
                              </Typography>
                            </div>
                          </div>
                        </div>
                        <SWAPSpace
                          size={index === testimonialData.length - 1 ? 0 : 16}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            ) : null}
            {/* 電腦版 */}
            {mobile ? null : (
              <div
                style={{
                  minHeight: '200px',
                }}
                className="testimonial_slide_wrap"
              >
                <Slider
                  {...{
                    infinite: true,
                    autoplay: true,
                    variableWidth: true,
                    pauseOnHover: true,
                    centerMode: true,
                    autoplaySpeed: 5000,
                    speed: 1000,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }}
                >
                  {testimonialData.map((item, index) => (
                    <div
                      key={`testimonial${index}`}
                      style={{ width: '400px', padding: '0 12px' }}
                    >
                      <SWAPSpace size={10} />
                      <div
                        style={{
                          background: '#ffffff',
                          boxShadow: '0px 6px 24px rgba(0, 0, 0, 0.1)',
                          padding: '24px',
                          borderRadius: '8px',
                        }}
                      >
                        <div
                          style={{ display: 'flex', justifyContent: 'stretch' }}
                        >
                          <img
                            src="https://swap-img.swap.work/landingPageImg2/testimonial/sign.png"
                            style={{
                              objectFit: 'contain',
                              marginRight: '12px',
                              marginTop: '3px',
                              objectPosition: 'top',
                            }}
                          />
                          <Typography variant="body1_loose" color="black800">
                            {item.comment}
                          </Typography>
                          <img
                            src="https://swap-img.swap.work/landingPageImg2/testimonial/sign_reverse.png"
                            style={{
                              objectFit: 'contain',
                              marginLeft: '12px',
                              width: '14px',
                              marginBottom: '4px',
                              objectPosition: 'bottom',
                            }}
                          />
                        </div>
                        <SWAPSpace size={24} />
                        <div
                          style={{
                            display: 'flex',
                            gap: '16px',
                            alignItems: 'center',
                          }}
                        >
                          <div>
                            <div className="testimonial_avatar">
                              <LazyLoadImg
                                src={item.imgUrl}
                                layout="intrinsic"
                                width="96px"
                                height="96px"
                                style={{
                                  width: '96px',
                                  height: '96px',
                                  borderRadius: '12px',
                                  overflow: 'hidden',
                                }}
                                alt="user image"
                                priority={false}
                                placeholder="empty"
                                objectFit="cover"
                              />
                            </div>
                          </div>
                          <div>
                            <Typography variant="h3" color="primary500">
                              {item.name}
                            </Typography>
                            <SWAPSpace size={6} />
                            <Typography variant="body1">
                              {item.profession}
                            </Typography>
                          </div>
                        </div>
                      </div>
                      <SWAPSpace size={24} />
                    </div>
                  ))}
                </Slider>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
})

const Pricing = () => {
  const [fee, setFee] = useState(0)
  const [feeRateData, setFeeRateData] = useState<
    Array<{
      month: number
      year: number
      rate: number
      name: string
      sponsor: number
    }>
  >([])

  const handleGetFeeRate = async () => {
    try {
      const res = await getPaymentRequestFeeRatesFromGuess()
      if (res && res.errors === null) {
        const payload = res.payload
          .map((item: any) => ({
            //調整成圖表讀取用的格式
            ...item,
            name: `${item.year}.${item.month}`,
            sponsor: 1,
          }))
          .filter((item: any) => new Date().getTime() > Number(item.started_at))
          .slice(-3)
        setFeeRateData(payload) //取得最近三期
        setFee(payload.slice(-1)[0].rate) //取得本期費率
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    handleGetFeeRate()
  }, [])

  return (
    <LoadToShow>
      <div
        style={{
          marginTop: '56px',
          width: '100%',
          overflowX: 'hidden',
        }}
      >
        <PricingHead />
        <PricingTable />
        <PricingRate feeRateData={feeRateData} fee={fee} />
        <FlowAndPricing fee={fee} />
        <PricingCta />
      </div>
    </LoadToShow>
  )
}

const socialProofPic1 = 'https://swap-img.swap.work/landingPageImg/Phoebe.jpg'
const socialProofPic2 = 'https://swap-img.swap.work/landingPageImg/Grayson.png'
const socialProofPic3 = 'https://swap-img.swap.work/landingPageImg/David.png'
const socialProofPic4 = 'https://swap-img.swap.work/landingPageImg/Tzu-Wei.jpg'
const socialProofPic5 =
  'https://swap-img.swap.work/landingPageImg/Lemon-Kao.jpg'
const socialProofPic6 = 'https://swap-img.swap.work/landingPageImg/gary.jpeg'
const socialProofPic7 = 'https://swap-img.swap.work/landingPageImg/Eric-Lin.jpg'

const socialProofPic1Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/Phoebe.jpeg'
const socialProofPic2Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/Grayson.png'
const socialProofPic3Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/David.png'
const socialProofPic4Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/Tzu-Wei.jpeg'
const socialProofPic5Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/Lemon-Kao.jpeg'
const socialProofPic6Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/gary.jpeg'
const socialProofPic7Blurred =
  'https://swap-img.swap.work/landingPageImgBlurred/Eric-Lin.jpeg'

export const testimonialData = [
  {
    name: 'Phoebe',
    profession: '攝影師',
    comment:
      '之前以個人名義接影片製作的案件時，時常在合約、報價單的來回上遇到困擾。用了 SWAP 之後，只要一個連結，就可以把匯款搞定。在執行案件的過程中，需要多少金額再從 SWAP 提領就好，非常方便。',
    imgUrl: socialProofPic1,
    blurredImgUrl: socialProofPic1Blurred,
    imgAlt: 'SWAP接案者使用推薦-1',
  },
  {
    name: 'Grayson',
    profession: '廣告投手',
    comment:
      '在跟客戶對接的時候，差點因為無法開發票這件事而把案子給丟了。但幸好有人推薦我SWAP，幫我把這個問題解決了，讓我成功順利接到了案子。',
    imgUrl: socialProofPic2,
    blurredImgUrl: socialProofPic2Blurred,
    imgAlt: 'SWAP接案者使用推薦-2',
  },
  {
    name: 'David',
    profession: '專案統籌',
    comment:
      '作為發包人員，我們同時在管理很多專案。感謝SWAP讓我能夠將每個專案做到獨立建檔管理。用一個網站就能輕鬆管理各項專案。',
    imgUrl: socialProofPic3,
    blurredImgUrl: socialProofPic3Blurred,
    imgAlt: 'SWAP接案者使用推薦-3',
  },
  {
    name: 'Tzu-Wei',
    profession: '三角點品牌顧問共同創辦人',
    businessUrl: 'https://trig.studio/',
    comment:
      '作為發案者我們時常需要跟各種類型的自由工作者合作，SWAP讓我能輕鬆管理專案，並能一鍵發款，輕鬆掌握案件進度。',
    imgUrl: socialProofPic4,
    blurredImgUrl: socialProofPic4Blurred,
    imgAlt: 'SWAP接案者使用推薦-4',
  },
  {
    name: 'Lemon-Kao',
    profession: '檸檬知識創新創辦人',
    businessUrl: 'https://lemonki.io/',
    comment:
      'SWAP是陪伴我的好夥伴，幫助我在個人工作者時期，有跟廠商合作的請款工具。待我創辦公司後，又可以讓外包夥伴能開發票給我，不僅節省我的行政成本，還讓我有更大的合作彈性。',
    imgUrl: socialProofPic5,
    blurredImgUrl: socialProofPic5Blurred,
    imgAlt: 'SWAP接案者使用推薦-5',
  },
  {
    name: 'Gary Liaw',
    profession: '醫療科技譯者',
    comment:
      '身為自由譯者，從找客戶到處理帳務，都要自己來。還好有 SWAP，讓帳務處理省了不少時間。遇到有特殊要求的業主，經由 SWAP 客服積極討論、迅速提供彈性方案，讓本來的麻煩事輕鬆解決，真的很方便！',
    imgUrl: socialProofPic6,
    blurredImgUrl: socialProofPic6Blurred,
    imgAlt: 'SWAP接案者使用推薦-6',
  },
  {
    name: 'Eric-Lin',
    profession: '目標規劃教練',
    comment:
      '透過 SWAP 便利又有效率的服務，我可以將更多精力和時間專注於我的服務，而不是耗費在這些繁複的行政財務工作上。',
    imgUrl: socialProofPic7,
    blurredImgUrl: socialProofPic7Blurred,
    imgAlt: 'SWAP接案者使用推薦-7',
  },
]

export default Pricing
