/**
 * 決定每個頁面的標籤
 */
import React from 'react'
import Head from 'next/head'

import { PathOr } from '../../utils/FunctionsBox'
import {
  Product_Name_Invoice,
  Product_Name_SearchEngine,
  Product_Name_Verified,
} from '../components/Pricing'

export const FavIcon = 'images/favicon.ico'
export const LogoMetaImage = `https://swap-img.swap.work/media/metaImg.png?v=${new Date().getTime()}`
const BIZ_APP_URL = 'https://swap.work/biz'
const BusinessMetaImage =
  'https://swap-img.swap.work/metaImg/SWAP_Biz_Meta_Img.png'
/**
 * 通用
 */
export const WebsiteName = 'SWAP 自由工作者的服務平台'
const WebsiteDescription =
  '超過 2,000 位自由工作者在 SWAP 平台上發展自由事業。一站整合請款、發票、帳務文件、申報所得、事業認證、接案履歷... 等功能，讓 SWAP 加速你的事業發展。榮獲設計、程式、行銷、影像、文字、顧問、語言.. 等各領域自由工作者的一致的推薦！'
const WebsiteNameBusiness = 'SWAP 企業版 發案公司的財務救星'
const WebsiteDescriptionBusiness = '一張發票搞定一切，向繁瑣的勞報資料說再見'

const expressUrl = APP_URL + '/landing/express'
const laborProjectHelperUrl = APP_URL + '/tools/laborproject'
const calculatorUrl = APP_URL + 'tools/calc'
const pricingUrl = APP_URL + '/pricing'
const faqUrl = APP_URL + '/faqs'
const termsUrl = APP_URL + '/terms'
const privacyPolicyUrl = APP_URL + '/privacy-policy'
const functionVerifyUrl = APP_URL + '/functions/verify'
const functionProfileUrl = APP_URL + '/functions/profile'
const functionInvoiceUrl = APP_URL + '/functions/invoice'
const InterviewHomeUrl = APP_URL + '/interview'
const taxCalcUrl = APP_URL + '/tools/incometax'
/**
 * 首頁
 */
export class AppHomeHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/" />
        <title>{WebsiteName}</title>
        <meta property="og:title" content={WebsiteName} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={APP_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * Biz首頁
 */
export class BusinessPageHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <title>{WebsiteNameBusiness}</title>
        <meta property="og:title" content={WebsiteNameBusiness} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:url" content={BIZ_APP_URL} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteNameBusiness} />
        <meta name="description" content={WebsiteDescriptionBusiness} />
      </Head>
    )
  }
}

/**
 * Express 頁面
 */
export class ExpressHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <title>{WebsiteName}</title>
        <meta property="og:title" content={WebsiteName} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={expressUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
      </Head>
    )
  }
}

/**
 * 授權頁面
 */
export class AppAuthHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <title>註冊與登入 | {WebsiteName}</title>
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 會員收款頁面
 * 棄用
 */
export class MemberProfileHead extends React.PureComponent<{
  profile: any
  pageUrl: string
}> {
  render() {
    const UserName =
      PathOr('', ['props', 'profile', 'first_name'], this) +
      PathOr('', ['props', 'profile', 'last_name'], this)
    const MemberPicture = PathOr('', ['props', 'profile', 'picture'], this)
    const MemberPageUrl = PathOr('', ['props', 'pageUrl'], this)
    return (
      <Head>
        <title>
          {UserName}的收款連結 | {WebsiteName}
        </title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta
          property="og:title"
          content={`${UserName}的收款連結 | ${WebsiteName}`}
        />
        <meta
          property="og:description"
          content={`${UserName}的收款連結 | ${WebsiteName}`}
        />
        <meta property="og:image" content={MemberPicture} />
        <meta property="og:url" content={MemberPageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
      </Head>
    )
  }
}

/**
 * 會員名片頁面
 * 棄用
 */
export class MemberCardHead extends React.PureComponent<{
  profile: any
  pageUrl: string
}> {
  render() {
    const UserName =
      PathOr('', ['props', 'profile', 'first_name'], this) +
      PathOr('', ['props', 'profile', 'last_name'], this)
    const MemberPicture = PathOr('', ['props', 'profile', 'picture'], this)
    const MemberPageUrl = PathOr('', ['props', 'pageUrl'], this)
    return (
      <Head>
        <title>
          {UserName}的名片 | {WebsiteName}
        </title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta
          property="og:title"
          content={`${UserName}的名片 | ${WebsiteName}`}
        />
        <meta
          property="og:description"
          content={`${UserName}的名片 | ${WebsiteName}`}
        />
        <meta property="og:image" content={MemberPicture} />
        <meta property="og:url" content={MemberPageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
      </Head>
    )
  }
}

/**
 * 會員首頁
 */
export class MemberHomeHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>會員首頁 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 會員引導頁面
 */
export class MemberInitHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>引導教學 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 重置密碼
 */
export class ResetPasswordHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>重置密碼 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 品牌首頁
 * 棄用
 */
export class BusinessHomeHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>品牌首頁 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * Biz合作夥伴
 */
export class BusinessPartnerHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>合作夥伴 | {WebsiteNameBusiness}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * Biz匯款群組
 */
export class BusinessProjectHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>匯款群組 | {WebsiteNameBusiness}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * Biz帳戶設定
 */
export class BusinessSettingHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>帳戶設定 | {WebsiteNameBusiness}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * Biz帳務總覽
 */
export class BusinessOverviewHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>帳務總覽 | {WebsiteNameBusiness}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * Biz價格方案頁面
 */
export class BizPricingHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>價格方案 | {WebsiteNameBusiness}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta
          property="og:title"
          content={`價格方案 | ${WebsiteNameBusiness}`}
        />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta name="description" content={WebsiteDescriptionBusiness} />
      </Head>
    )
  }
}

/**
 * Biz常見問答頁面
 */
export class BizFaqsHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>常見問答 | {WebsiteNameBusiness}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta
          property="og:title"
          content={`常見問答 | ${WebsiteNameBusiness}`}
        />
        <meta property="og:image" content={BusinessMetaImage} />
        <meta property="og:description" content={WebsiteDescriptionBusiness} />
        <meta name="description" content={WebsiteDescriptionBusiness} />
      </Head>
    )
  }
}

/**
 * 品牌履歷頁面
 * 棄用
 */
export class BusinessProfileHead extends React.PureComponent<{
  profile: any
  pageUrl: string
}> {
  render() {
    const UserName = PathOr('', ['props', 'profile', 'name'], this)
    const MemberPicture = PathOr('', ['props', 'profile', 'picture'], this)
    const MemberPageUrl = PathOr('', ['props', 'pageUrl'], this)
    return (
      <Head>
        <title>
          {UserName} | {WebsiteName}
        </title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:title" content={`${UserName} | ${WebsiteName}`} />
        <meta
          property="og:description"
          content={`${UserName} | ${WebsiteName}`}
        />
        <meta property="og:image" content={MemberPicture} />
        <meta property="og:url" content={MemberPageUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
      </Head>
    )
  }
}

/**
 * Biz<->SOHO 邀請成為合作夥伴頁面
 */
export function BusinessPartnerInviteHead(props: any) {
  return (
    <Head>
      <title>
        {props.companyName}邀請您 | {WebsiteNameBusiness}
      </title>
      <link rel="shortcut icon" href={FavIcon} />
      <meta property="og:description" content={WebsiteDescriptionBusiness} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 管理員後台
 */
export class AdminDashboardHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>管理後台 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 訊息視窗
 */
export class MessagePageHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>合作訊息 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 隱私權頁面
 */
export class PrivacyPolicyPageHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>隱私權政策 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:url" content={privacyPolicyUrl} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 帳戶設定頁面
 */
export class AccountSettingsHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>帳戶設定 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 請款手續費紀錄頁面
 */
export class BillsHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>請款手續費紀錄 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 申請扣繳單頁面
 */
export class WithholdingStatementHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>財力證明 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 專案管理頁面
 */
export class ProjectsHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>我的專案 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 我的方案頁面
 */
export class MemberPlanHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>我的方案 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 會員優惠券頁面
 */
export class DiscountHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>優惠券 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 功能導覽頁面
 */
export class OnBoardingHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>功能導覽 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 合作企業
 */
export class BizPartnerHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>合作企業 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 工具 申報類別小幫手
 */
export class ToolsLaborProjectHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>申報類別小幫手 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/tools/laborproject" />
        <meta property="og:url" content={laborProjectHelperUrl} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 工具 案件產生器
 * 棄用
 */
export class ToolsCaseHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>SWAP 商業合作案產生器 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 工具 部落客
 * 棄用
 */
export class ToolsBloggersHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>SWAP 部落客發案小幫手 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 文件，服務條款
 */
export class TermHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>網站服務條款</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:url" content={termsUrl} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 文件，服務條款
 * 棄用
 */
export class LineAccountHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>SWAP 接案合作站 </title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
      </Head>
    )
  }
}

/**
 * 收費方案頁面
 */
export class PricingHead extends React.PureComponent {
  render() {
    const title = `收費方案 | ${WebsiteName}`
    return (
      <Head>
        <title>{title}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/pricing" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={pricingUrl} />
        <meta property="og:type" content="website" />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * 常見問答頁面
 */
export class FaqsHead extends React.PureComponent {
  render() {
    const title = `常見問答 | ${WebsiteName}`
    return (
      <Head>
        <title>{title}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/faqs" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={faqUrl} />
        <meta property="og:type" content="website" />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * 功能頁面：事業認證
 */
export class FunctionVerifyHead extends React.PureComponent {
  render() {
    const title = `${Product_Name_Verified} | ${WebsiteName}`
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/functions/verify" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={functionVerifyUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * 功能頁面：曝光媒合
 */
export class FunctionProfileHead extends React.PureComponent {
  render() {
    const title = `${Product_Name_SearchEngine} | ${WebsiteName}`
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/functions/profile" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={functionProfileUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * 功能頁面：案件請款
 */
export class FunctionInvoiceHead extends React.PureComponent {
  render() {
    const title = `${Product_Name_Invoice} | ${WebsiteName}`
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/functions/invoice" />
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={functionInvoiceUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * 請款單頁面
 */
export function PaymentRequestHead(props: any) {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>
        {props.member && !props.error
          ? props.member.verified_name + '的請款單'
          : '找無請款單資源'}
      </title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 請款單 ｜ 建立虛擬帳號頁面
 */
export function CreateVirtualAccountHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>建立付款虛擬帳號</title>

      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 請款單 ｜ 虛擬帳號建立成功頁面
 */
export function VirtualAccountCreatedHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>虛擬帳號建立完成</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 請款單計算機
 */
export function CalculatorHead(props: any) {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <link rel="canonical" href="https://swap.work/tools/calc" />
      <title>{props.hash ? '案件報價' : '請款單計算機'}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={calculatorUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
    </Head>
  )
}

/**
 * 畫面讀取中
 */
export function LoadingPageHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>畫面讀取中</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
    </Head>
  )
}

/**
 * 報價單頁面
 */
export function QuotationHead(props: any) {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>
        {props.member && !props.error
          ? props.member.verified_name + '的報價單'
          : '找無報價單資源'}
      </title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 報價單頁面
 */
export function PublicProjectHead({ projectName }: { projectName: string }) {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>{projectName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 我的文件
 */
export class FileHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <title>我的文件 | {WebsiteName}</title>
        <link rel="shortcut icon" href={FavIcon} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta name="robots" content="none, noarchive" />
      </Head>
    )
  }
}

/**
 * 我的請款單 (Dashboard的)
 */
export function PaymentRequestsHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>我的請款單 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 *  接案履歷後台
 */
export function ProfileHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>接案履歷 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 *  創建接案履歷
 */
export function ProfileCreateHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>創建接案履歷 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 *  Landing page 找工作者
 */
export function FindProfileHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>自由工作者搜尋引擎 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta
        property="og:description"
        content={
          '找自由工作者、Freelancer、自由接案者？透過 SWAP 找到實名認證的工作者，免費瀏覽工作者的接案履歷，找到最合適的合作夥伴，一站式平台服務讓你輕鬆發案，順利結案！'
        }
      />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta
        name="description"
        content="找自由工作者、Freelancer、自由接案者？透過 SWAP 找到實名認證的工作者，免費瀏覽工作者的接案履歷，找到最合適的合作夥伴，一站式平台服務讓你輕鬆發案，順利結案！"
      />
    </Head>
  )
}

/**
 *  接案履歷(客戶看)
 */
export function ProfileForGuessHead({
  id,
  name,
  description,
  img,
}: {
  id: string
  name: string
  description: string
  img: string
}) {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>{name} | SWAP</title>
      <meta property="og:title" content={`${name} | SWAP`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={`${APP_URL}/profile/${id}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="description" content={description} />
    </Head>
  )
}

/**
 *  訂閱贊助方案
 */
export function SponsorHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>訂閱贊助方案 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 *  訂閱贊助方案訂單
 */
export function SponsorOrderHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>贊助方案訂單 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * SWAP 平台計算機
 */
export function CalculatorV3Head() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <link rel="canonical" href="https://swap.work/tools/calc/v3" />
      <title>SWAP 平台計算機</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={calculatorUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
    </Head>
  )
}

/**
 * 專訪頁面目錄
 */
export class InterviewHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href="https://swap.work/" />
        <title>自由工作者專訪 | {WebsiteName}</title>
        <meta property="og:title" content={`自由工作者專訪 | ${WebsiteName}`} />
        <meta property="og:description" content={WebsiteDescription} />
        <meta
          property="og:image"
          content={`https://swap-img.swap.work/interview/interview.png?v=${new Date().getTime()}`}
        />
        <meta property="og:url" content={InterviewHomeUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 * SOHO 所得稅計算機
 */
export class SOHOCalcHead extends React.PureComponent {
  render() {
    return (
      <Head>
        <link rel="shortcut icon" href={FavIcon} />
        <link rel="canonical" href={taxCalcUrl} />
        <title>自由工作者所得稅試算機 | {WebsiteName}</title>
        <meta
          property="og:title"
          content={`自由工作者所得稅試算機 | ${WebsiteName}`}
        />
        <meta property="og:description" content={WebsiteDescription} />
        <meta property="og:image" content={LogoMetaImage} />
        <meta property="og:url" content={taxCalcUrl} />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={WebsiteName} />
        <meta name="description" content={WebsiteDescription} />
      </Head>
    )
  }
}

/**
 *  接案社團
 */
export function GroupHead() {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>接案社團 | {WebsiteName}</title>
      <meta property="og:title" content={WebsiteName} />
      <meta property="og:description" content={WebsiteDescription} />
      <meta property="og:image" content={LogoMetaImage} />
      <meta property="og:url" content={APP_URL} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 分享的接案社團
 */
export function GroupPublicHead({
  id,
  name,
  description,
  img,
}: {
  id: string
  name: string
  description: string
  img: string
}) {
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <title>接案社團 | {WebsiteName}</title>
      <meta property="og:title" content={`${name} | SWAP`} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={img} />
      <meta property="og:url" content={`${APP_URL}/public/group/${id}`} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={WebsiteName} />
      <meta name="description" content={description} />
      <meta name="robots" content="none, noarchive" />
    </Head>
  )
}

/**
 * 自助勞報小工具
 */
export function SelfLaborRewardHead() {
  const _name = `自助勞報單小工具 | ${WebsiteName}`
  const _url = `https://swap.work/tools/self-labor-reward-tool`
  const _description = `3 分鐘，建立一張完整的勞報單。你還在用 word 自己拉勞報單嗎，還在用大把時間研究所得類別、國籍、代扣繳金額？這樣做真的太沒效率了，試試由 SWAP 所開發的小工具吧，輕鬆搞定勞務報酬單！`
  const _img = `https://i.imgur.com/zD9GWBL.jpeg`
  return (
    <Head>
      <link rel="shortcut icon" href={FavIcon} />
      <link rel="canonical" href={_url} />
      <meta property="og:url" content={_url} />
      <title>{_name}</title>
      <meta property="og:title" content={_name} />
      <meta property="og:site_name" content={_name} />
      <meta name="description" content={_description} />
      <meta property="og:description" content={_description} />
      <meta property="og:image" content={_img} />
      <meta property="og:type" content="website" />
    </Head>
  )
}
